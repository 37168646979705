import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Image, { IMAGES } from '../components/image'
import DetailText from '../components/detail-text'
import SEO from '../components/seo'

const IndexPage = ({ location }) => (
  <Layout location={location} crumbLabel="Home">
    <SEO title="Home" />
    <div
      className="
      grid
      pb-4 sm:pb-0
      sm:col-span-6 sm:grid-cols-2
      md:justify-i-end
      "
    >
      <div className="" />
      <div className="md:max-w-xs w-full">
        <Image filename={IMAGES.sleepyFoxLogotype} />
      </div>
    </div>
    <div className="
      grid
      sm:col-span-6 sm:grid-cols-6 sm:gap-4
      md:col-span-6 md:grid-cols-2 md:gap-4
      "
    >
      <div className="sm:col-span-5 md:col-span-1">
        <DetailText
          header="About"
          body="We are a small, independent studio based in the pacific northwest focused on web and mobile app development."
        />
        <DetailText
          header="Projects"
          body={(
            <>
              <p><Link to="/jewel-tones-blast/">Jewel Tones Blast</Link></p>
              <p><Link to="/jewel-tones-twist/">Jewel Tones Twist</Link></p>
            </>
            )}
        />
        <DetailText
          header="Contact"
          body={<a href="mailto:wakeup@thesleepyfox.studio">wakeup@thesleepyfox.studio</a>}
        />
      </div>
    </div>
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default IndexPage
